<template>
  <!-- Aquí va el HTML del componente -->
  <div class="canva">
  <div class="name">{{ nombre }}</div>
    <img :src="require('../../../assets/imgs/pdfs/bg_diploma.png')" class="bg" />
  </div>
</template>
  
<script>
export default {
  props: {
    nombre: {
      type: String,
      required: true
    }
  },
  data() {
    return {
    }
  }
}
</script>
  
<style scoped>
@import '../../../styles/PdfStyles.css'
</style>