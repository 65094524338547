<template>
    <!-- Aquí va el HTML del componente -->
    <div class="canva">
        <img :src="require('../../../assets/imgs/pdfs/tips_huella_alta.png')" class="bg" />
    </div>
</template>
    
<script>
export default {
    data() {
        return {
        }
    }
}
</script>
    
<style scoped>
@import '../../../styles/PdfStyles.css'
</style>