<template>
    <!-- Aquí va el HTML del componente -->
    <div class="canva">
        <div class="name_resultados">{{ nombre }}</div>
        <div class="co2_resultados">{{ co2 }} toneladas de CO<sub>2</sub>/año </div>
        <div class="arboles_resultados">{{ arboles }} árboles</div>
        <img :src="require('../../../assets/imgs/pdfs/bg_resultados_alta.png')" class="bg" />
    </div>
</template>
    
<script>
export default {
    props: {
        nombre: {
            type: String,
            required: true
        },
        co2: {
            type: Number,
            required: true
        },
        arboles: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
        }
    }
}
</script>
    
<style scoped>
@import '../../../styles/PdfStyles.css'
</style>