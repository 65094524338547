<template>
    <ResultadosHuellaAlta arboles="30" />
</template>
  
<script>
import ResultadosHuellaAlta from './Resultados/ResultadosHuellaAlta.vue';

export default {
    components: {
    ResultadosHuellaAlta
},
    // Aquí van las opciones del componente
    data() {
        return {
        }
    }
}
</script>
  
<style scoped>
/* Aquí van los estilos del componente */
</style>